<template>
  <div>
    <div v-if="loadingNotifications">
      <p class="h5">Loading alerts</p>
      <div class="rounded-2xl bg-neutral-50 p-4 flex flex-row gap-4 items-center animate-pulse">
        <div class="bg-neutral-100 p-4 rounded-full animate-pulse"></div>
      </div>
    </div>
    <div
      v-if="!siteNotifications.length && !loadingNotifications && !notificationsError"
      class="rounded-2xl bg-neutral-0 p-4 flex flex-row gap-4 items-center"
    >
      <ic-bell-check class="text-bb-success-text bg-bb-success-bg rounded-full" />
      <p class="font bold">All caught up! <span class="font-medium">No alerts to address.</span></p>
    </div>
    <div v-if="siteNotifications.length && !loadingNotifications">
      <div class="flex flex-row items-center mb-4 gap-2">
        <IcBellAlert
          class="text-bb-error"
          :size="26"
        />
        <p class="h5 font-bold">{{ siteNotificationsCount }} new alerts</p>
      </div>
      <div
        v-for="notification in siteNotifications"
        :key="notification.id"
        class="w-full"
      >
        <BannerCard
          v-if="isAnomalyTypeSupported(notification.anomaly_type)"
          class="mb-4"
          :type="'alert'"
          @resolve="resolve(notification)"
        >
          <template #header>
            <div v-if="notification.anomaly_type === 'account no data'">
              <p class="h5">
                <span class="font-extrabold">{{ notification.site_name }}</span> connection to Google Ads appears
                inactive
              </p>
            </div>
            <div v-if="notification.anomaly_type === 'campaign flat line'">
              <p class="h5">
                <span class="font-extrabold">{{ notification.site_name }}</span
                >'s campaign <span class="font-extrabold">{{ notification.google_campaign_name }}</span> has stopped
                running
              </p>
            </div>
            <div v-if="notification.anomaly_type === 'metric out of range'">
              <p class="h5">
                Unusual value for metric <span>{{ getMetricDetails(notification.anomaly_details) }}</span> on
                <span class="font-extrabold">{{ notification.site_name }}</span
                >'s campaign <span class="font-extrabold">{{ notification.google_campaign_name }}</span>
              </p>
            </div>
          </template>
          <template #content>
            <div v-if="notification.anomaly_type === 'account no data'">
              <p class="p2">
                The Google Ads account
                <span class="font-extrabold"
                  >{{ notification.google_customer_name }} ({{
                    addDashesToGoogleAccountId(notification.google_customer_id)
                  }})</span
                >
                seems to have ceased activity. Please review Google Ads for potential error messages.
              </p>
            </div>
            <div v-if="notification.anomaly_type === 'campaign flat line'">
              <p class="p2">
                This campaign has stopped receiving data. Check Google Ads account
                <span class="font-extrabold"
                  >{{ notification.google_customer_name }} ({{
                    addDashesToGoogleAccountId(notification.google_customer_id)
                  }})</span
                >
                for any possible issues or error messages.
              </p>
            </div>
            <div v-if="notification.anomaly_type === 'metric out of range'">
              <p class="p2">
                The <span>{{ getMetricDetails(notification.anomaly_details) }}</span> value is outside of the usual
                range for this campaign. Check Google Ads account
                <span class="font-extrabold"
                  >{{ notification.google_customer_name }} ({{
                    addDashesToGoogleAccountId(notification.google_customer_id)
                  }})</span
                >
                for any issues or recent changes.
              </p>
            </div>
          </template>
          <template #date>
            <p class="p4">
              {{ formattedDate(notification.anomaly_day) }}
            </p>
          </template>
        </BannerCard>
      </div>
      <div class="text-center items-center">
        <router-link
          :to="{ name: 'portfolio' }"
          class="text-bb-brand-purple inline-flex"
        >
          See all Alerts in the Portfolio
          <ic-chevron direction="right" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Toast from '@/components/shared/Toast.vue'
import BannerCard from '@/components/card/BannerCard.vue'
import IcBellAlert from '@/components/icon/brightbid/ic-bell-alert.vue'
import IcBellCheck from '@/components/icon/brightbid/ic-bell-check.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'
import dayjs from 'dayjs'
import { addDashesToGoogleAccountId } from '@/core/store'
import { METRIC_LABEL_MAPPING } from '../../core/constants'

export default {
  name: 'AlertsWidget',
  components: {
    BannerCard,
    IcBellAlert,
    IcBellCheck,
    IcChevron,
  },
  props: {
    siteId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('notifications', [
      'siteNotifications',
      'siteNotificationsCount',
      'loadingNotifications',
      'notificationsError',
    ]),
  },
  watch: {
    siteId() {
      if (!this.siteId) return
      this.loading = true
      this.fetchSiteNotifications({ siteId: this.siteId, limit: 3 })
      this.loading = false
    },
  },
  mounted() {
    if (this.siteId) {
      this.fetchSiteNotifications({ siteId: this.siteId, limit: 3 })
    }
  },
  methods: {
    ...mapActions('notifications', ['fetchSiteNotifications']),
    addDashesToGoogleAccountId,
    async resolve(notification) {
      try {
        await axios.post(`search/site/${this.siteId}/anomaly/${notification.id}/resolve`)
        await this.fetchSiteNotifications({ siteId: this.siteId, limit: 3 })
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Notification resolved',
            type: 'success',
          },
        })
      } catch {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to resolve notification',
            type: 'error',
          },
        })
      }
    },
    formattedDate(date) {
      return dayjs(date).format('DD/MM/YY')
    },
    getMetricDetails(details) {
      const metric = details.split('=')[0].toLowerCase()
      return METRIC_LABEL_MAPPING[metric] || ''
    },
    isAnomalyTypeSupported(type) {
      return ['metric out of range', 'campaign flat line', 'account no data'].includes(type)
    },
  },
}
</script>
